import React from 'react'

const DemoHead = () => {
  const title = 'React Hooks 入門 - HooksとReduxを組み合わせて最新のフロントエンド状態管理手法を習得しよう'

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <span className="navbar-text">
        Udemyのコース『<a href="https://www.udemy.com/2329814/" target="_blank" rel="noreferrer noopener">{title}</a>』のデモ画面
      </span>
    </nav>
  )
}

export default DemoHead
